import { StudentInvoice } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import InvoicesTable from './InvoicesTable';
import { useTypedSelector } from '@Features/store';

const Invoices = () => {
	const { url, path } = useRouteMatch();
	const { id } = useParams<{ id: string }>();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Students);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	const { setURL, setCurrentRouteGroup } = useInvalidateRouteGroup({
		routeGroup: StudentInvoice,
		baseURL: `${url}`,
		routePlacement: 2,
		mode: 'end',
	});
	useEffect(() => {
		setURL(url);
		setCurrentRouteGroup?.(StudentInvoice);
	}, [setURL]);

	return (
		<>
			<Switch>
				{StudentInvoice && !Array.isArray(StudentInvoice) && (
					<>
						<Route exact path={url}>
							{currentEntity && getSingle === 'fulfilled' && <InvoicesTable />}
						</Route>
						{StudentInvoice.routes.map((_, index) => {
						
							return (
								<Route
									strict
									path={`${path}/${_.route}`}
									// key={`${_.route}-${index}`}
								>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</Switch>
		</>
	);
};

export default Invoices;
