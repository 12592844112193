import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import PromoCodesTable from './Pages/ScratchCardsTable/PromoCodesTable';
import { PromoCodesRoute } from './Constants/routes';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import GenerateCodes from './components/GenerateCode';

const ScratchCards = () => {
	const { path, url } = useRouteMatch();
	return (
		<div>
			<RouteProvider path={path}>
				<Switch>
					<Route exact path={url} component={PromoCodesTable} />

					{PromoCodesRoute.routes.map((_) => {
					
						return (
							<Route key={_.route} path={`${path}/${_.route}`}>
								{_.component}
							</Route>
						);
					})}
				</Switch>
			</RouteProvider>
		</div>
	);
};

export default ScratchCards;
