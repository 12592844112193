import React, { useCallback, useRef, useState } from 'react';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import TableCell from '@mui/material/TableCell';
import NoResults from '@Components/NoResults/NoResults';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import styled from 'styled-components';
import api from '@Services/api';
import MainTable, { MainTableRow } from '@Components/MainTable/MainTable';
import { TableHeader } from '@Features/generics/generics.type';
import { FlexLayout } from '@Styled/utilities';
import { useTypedSelector } from '@Features/store';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { importsGetAll } from '@Pages/Imports/Slice/Imports.slice';
import { useDispatch } from 'react-redux';
import {
	Icon,
	RadioGroup,
	Radio,
	FormControlLabel,
	Button,
} from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { CSVLink } from 'react-csv';
import { testImportsGetAll } from './Slice/Imports.slice';
import { TestImportsRequester } from './Services/Imports.req';
import { TestImportType, importsHandlerMap } from './Types/Imports.types';
import { Typography } from '@eduact/ed-system';

const tableHeaders: Array<TableHeader> = [
	{
		displayName: 'ID',
		fieldName: 'id',
		width: '10%',
	},
	{
		displayName: 'Status',
		fieldName: 'status',
		width: '10%',
	},
	{
		displayName: 'Imported',
		fieldName: 'imported_count',
		width: '10%',
	},
	{
		displayName: 'Failed',
		fieldName: 'failed_count',
		width: '10%',
	},
	{
		displayName: 'Description',
		fieldName: 'description',
		width: '25%',
	},
	{
		displayName: 'Import date',
		fieldName: 'created_at',
		width: '20%',
	},
	{
		displayName: 'Data',
		fieldName: 'imported_data',
		width: '20%',
		canSort: false,
	},
	// {
	// 	displayName: 'Failed data',
	// 	fieldName: 'failed_data',
	// 	width: '15%',
	// },
];

const importDataHeader = [
	{ key: 'username', label: 'User name' },
	{ key: 'id', label: 'ID' },
	{ key: 'flag', label: 'Flag' },
];
const failedDataHeader = [
	{ key: 'username', label: 'User name' },
	{ key: 'id', label: 'ID' },
	{ key: 'reason', label: 'Reason' },
];

const instructorImportDataHeader = [
	{ key: 'code', label: 'Code' },
	{ key: 'label', label: 'Label' },
	{ key: 'group_name', label: 'Group name' },
];
const instructorFailedDataHeader = [
	{ key: 'group_name', label: 'Group name' },
	{ key: 'reason', label: 'Reason' },
];

const ImportsTable: React.FC = () => {
	const { meta, filters, fetchAll, query, dateRange, sortBy } =
		useTypedSelector((state) => state.TestImports);
	const [fileValue, setFileValue] = useState<any>(null);
	const [action, setAction] = useState<TestImportType>('attempts');
	const auth = useTypedSelector((state) => state.auth);

	const [refetch, setRefetch] = useState(false);
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const inputFileRef = useRef<HTMLInputElement>(null);

	const handleChooseFile = () => {
		if (inputFileRef.current) {
			inputFileRef.current.value = '';
			inputFileRef.current.click();
		}
	};

	const { currentEntity } = useTypedSelector((s) => s.Tests);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files.item(0);
		if (file?.type === 'text/csv') setFileValue(file);
		else {
			displaySnackbar('error', 'Accept only csv files');
			setFileValue(null);
		}
	};

	const importFile = async () => {
		try {
			if (!fileValue) {
				displaySnackbar('error', 'Please upload file');
				return;
			}
			if (!currentEntity || !currentEntity.id) return;

			const formData = new FormData();
			formData.append('file', fileValue);
			formData.append('test_id', currentEntity.id.toString());
			await importsHandlerMap[action](formData);
			setFileValue(null);
			setRefetch(true);
		} catch (error) {}
	};

	const onPageRequestCallback = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (currentEntity.id)
				dispatch(
					testImportsGetAll({
						filters,
						page: activePage,
						perPage: perPage ?? 10,
						query,
						sortBy,
						from: startDate,
						to: endDate,
						test_id: currentEntity.id,
					})
				);
			setRefetch(false);
		},
		[currentEntity.id]
	);


	return (
		<>
			<FlexLayout flexDirection="column" mb="40px">
				<input
					type="file"
					accept=".csv"
					hidden
					onChange={handleFileChange}
					ref={inputFileRef}
				/>
				<UploadButton
					onClick={handleChooseFile}
					startIcon={<Icon>upload_file</Icon>}
					variant="outlined"
					color="primary"
				>
					Upload File
				</UploadButton>
				{fileValue && (
					<FlexLayout
						minWidth="250px"
						width="fit-content"
						marginLeft="10px"
						justifyContent="space-between"
					>
						<p style={{ fontSize: '18px' }}>{fileValue.name}</p>
						<EdIcon
							style={{
								color: '#f74343',
								marginLeft: '10px',
								marginTop: '-5px',
								cursor: 'pointer',
							}}
							fontSize="small"
							onClick={() => setFileValue(null)}
						>
							<Icon>delete</Icon>
						</EdIcon>
					</FlexLayout>
				)}
			</FlexLayout>
			<FlexLayout mb="50px">
				<RadioGroup
					row
					defaultValue="attempts"
					style={{ flex: 1.5 }}
					onChange={(event, value) => {
						setAction(value as TestImportType);
					}}
				>
					<FormControlLabel
						value="questions"
						label="Test Questions"
						control={<Radio />}
					/>
					<FormControlLabel
						value="attempts"
						label="Test Attempts"
						control={<Radio />}
					/>
				</RadioGroup>
				<FlexLayout justifyContent="flex-end">
					<RoundButton
						onClick={importFile}
						sx={{ marginRight: '1rem' }}
						startIcon={<EdIcon>redo</EdIcon>}
						variant="contained"
					>
						Import
					</RoundButton>
				</FlexLayout>
			</FlexLayout>

			<MainTable
				tableFilters={filters}
				refetch={refetch}
				tableHeads={tableHeaders}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestCallback}
				RowsperPage={meta?.per_page}
				pageNum={meta?.current_page}
				$hasActions
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={tableHeaders.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll.map((importValue) => {
						return (
							<MainTableRow key={importValue?.id} hover>
								<TableCell>
									<Typography fontSize={'12px'}>
										{importValue?.id || '-'}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography fontSize={'12px'}>
										{importValue?.status || '-'}
									</Typography>
								</TableCell>
								<TableCell>{importValue?.imported_count || '-'}</TableCell>
								<TableCell>{importValue?.failed_count || '-'}</TableCell>
								<TableCell
									style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
								>
									{importValue?.description || '-'}
								</TableCell>
								<TableCell>
									<DateTimeFormatter date={importValue.created_at} />
								</TableCell>
								<TableCell>
									<FlexLayout gridGap={'1rem'} justifyContent={'center'}>
										{JSON.parse(importValue.imported_data)?.length > 0 ? (
											<CSVLink
												data={JSON.parse(importValue.imported_data)}
												filename={'ImportedData' + importValue.id + '.csv'}
											>
												<StyledImportDataButton ImportedFlag={true}>
													<EdIcon>download</EdIcon>
												</StyledImportDataButton>
											</CSVLink>
										) : (
											<StyledImportDataButton
												ImportedFlag={true}
												onClick={() => {
													displaySnackbar('error', 'No data to download');
												}}
											>
												<EdIcon>download</EdIcon>
											</StyledImportDataButton>
										)}
										{JSON.parse(importValue.failed_data)?.length > 0 ? (
											<CSVLink
												data={JSON.parse(importValue.failed_data)}
												filename={'FailedData' + importValue.id + '.csv'}
											>
												<StyledImportDataButton ImportedFlag={false}>
													<EdIcon>download</EdIcon>
												</StyledImportDataButton>
											</CSVLink>
										) : (
											<StyledImportDataButton
												ImportedFlag={false}
												onClick={() => {
													displaySnackbar('error', 'No data to download');
												}}
											>
												<EdIcon>download</EdIcon>
											</StyledImportDataButton>
										)}
									</FlexLayout>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const StyledImportDataButton = styled.button<{ ImportedFlag: boolean }>`
	width: 34px;
	height: 34px;
	border-radius: 6px;
	padding-top: 5px;
	border: none;
	cursor: pointer;
	background-color: ${(props) => (props.ImportedFlag ? '#54C0FC' : '#f74343')};
	color: white;
`;

const UploadButton = styled(Button)`
	width: fit-content;
	margin-bottom: 20px;
`;

export default ImportsTable;
