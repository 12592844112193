import { useTypedSelector } from '@Features/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditAssistantInfo from './EditNoteInfo';
import EditNoteInfo from './EditNoteInfo';
import { notesGetSingle } from '../../Slices/Notes.Slices';

const EditNote = () => {
	const {id,...params} = useParams<{ id: string }>();
	 
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Notes);

	const dispatch = useDispatch();
	useEffect(() => {
		if (id) {
			dispatch(notesGetSingle({ id }));
		}
	}, []);
	return (
		<>{currentEntity && getSingle === 'fulfilled' && <EditNoteInfo />}</>
	);
};

export default EditNote;
