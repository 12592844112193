import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	IconButton,
	Paper,
	TextField,
} from '@mui/material';
import {
	COURSE_SCHEMA,
	CURRICULUM_FORM,
} from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import { ClassroomCoursesRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomCourses.req';
import {
	Course,
	CoursePayload,
} from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { FC, useContext } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
	CurriculumContext,
	CurriculumCourseContext,
} from '../../Context/Curriculum.context';

type Props = {
	control: Control<CURRICULUM_FORM>;
	course: Course;
};
const CourseEditForm: FC<Props> = ({ course, control }) => {
	const { index, removeCourse, updateCourse, dragHandlers } = useContext(
		CurriculumCourseContext
	);
	const { displaySnackbar } = useSnackbar();
	const {
		control: CourseControl,
		formState,
		getValues,
		watch,
		reset,
	} = useForm<CoursePayload>({
		resolver: yupResolver(COURSE_SCHEMA),
		mode: 'all',
		defaultValues: course,
	});

	const watchIsFree = watch('isFree');

	const onDelete = async () => {
		if (!course.id) return;
		const { status } = await ClassroomCoursesRequester.getInstance().delete(
			course.id
		);
		if (status === 200) {
			removeCourse(index);
			displaySnackbar('success', 'Course deleted successfully');
		} else {
			displaySnackbar('error', "Couldn't delete course");
		}
	};

	const onUpdate = async () => {
		const { data, status } =
			await ClassroomCoursesRequester.getInstance().update(getValues());
		if (status === 200) {
			updateCourse(index, data.data as Course);
			displaySnackbar('success', 'Course updated successfully');
			reset(data.data);
		} else {
			displaySnackbar('error', "Couldn't update course");
		}
	};

	const { url, path } = useRouteMatch();

	const history = useHistory();
	const handleNavigateToContent = () => {
	
		const toNavigateURL = url.replace(
			'curriculum',
			`course/${course.id}/edit/content`
		);
		history.push({ pathname: toNavigateURL });
	};
	return (
		<FlexLayout justifyContent="space-between" alignItems="center">
			<CourseFormPaper>
				<FlexLayout alignItems="center" gridGap={'1rem'}>
					{dragHandlers && <DragButton dragHandlers={dragHandlers} />}
					<FlexLayout minWidth="15rem">
						<Controller
							control={CourseControl}
							name="name"
							render={({ field, fieldState }) => {
								return (
									<TextField
										{...field}
										error={fieldState.invalid}
										fullWidth
										size="small"
										placeholder="Course name"
									/>
								);
							}}
						/>
					</FlexLayout>
					<FlexLayout maxWidth="10rem">
						<Controller
							control={CourseControl}
							name="code"
							render={({ field, fieldState }) => {
								return (
									<TextField
										{...field}
										error={fieldState.invalid}
										size="small"
										placeholder="Code"
									/>
								);
							}}
						/>
					</FlexLayout>
					<FlexLayout maxWidth="10rem">
						<Controller
							control={CourseControl}
							name="price"
							render={({ field: { onChange, ...inputProps }, fieldState }) => {
								return (
									<EdNumericInput
										disabled={watchIsFree}
										{...inputProps}
										onChange={onChange}
										error={fieldState.invalid}
										size="small"
										placeholder="Price"
									/>
								);
							}}
						/>
					</FlexLayout>

					<Controller
						control={CourseControl}
						name="isFree"
						render={({ field: { value, onChange, ...inputProps } }) => {
							return (
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												{...inputProps}
												checked={value}
												onChange={(e, checked) => {
													onChange(checked);
												}}
											/>
										}
										label="Free"
									/>
								</FormGroup>
							);
						}}
					/>
					<EdButton
						minWidth={'8rem'}
						onClick={handleNavigateToContent}
						variant="outlined"
						edcolor="cadet"
						startIcon={<EdIcon>add</EdIcon>}
					>
						Content
					</EdButton>
					{formState.isDirty && (
						<IconButton onClick={onUpdate}>
							<EdAnimatedIcon animation="shake">
								<EdIcon>edit</EdIcon>
							</EdAnimatedIcon>
						</IconButton>
					)}
				</FlexLayout>
			</CourseFormPaper>
			<IconButton onClick={onDelete}>
				<EdIcon>delete</EdIcon>
			</IconButton>
		</FlexLayout>
	);
};

export default CourseEditForm;

const CourseFormPaper = styled(Paper)`
	padding: 0.375rem 1rem;
	margin: 0.625rem 0;
	flex: 1;
`;
