import React, { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import {
	getClassroomByLabel,
	getClassroomSections,
	getCourseById,
} from '@Features/classrooms/classroomSlice';
import { useHistory, useParams } from 'react-router-dom';
import {
	createCourse,
	updateCourse,
} from '@Services/classrooms/classrooms.req';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';

import { Divider, FlexLayout, Spacer } from '@Styled/utilities';
import { Box } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FormMode } from '@Pages/Instructors/instructor.types';
import { CourseFormData, CourseFormSchema } from '../CourseForm.types';

import {
	ActiosnContainer,
	SectionLayout,
} from '@Pages/ClassRooms/Pages/AddClassroom/Components/AddClassroomBasicInfo/AddClassroomBasicInfo';
import { toIsoString } from '@Utils/ToISODate';
import { SpanStyle, TitleStyled } from '../Form.Styles';
import { DevTool } from '@hookform/devtools';
import { unitsGetAll } from './CourseContent/Pages/Contents/Slices/Units.slice';
import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';

interface Props {
	mode: FormMode;
}

const BasicInfo: React.FC<Props> = ({ mode }) => {
	const history = useHistory();

	const { currentCourse, currentClassroomSections, currentClassroom } =
		useTypedSelector((state) => state.classroom);

	const { fetchAll } = useTypedSelector((state) => state.Units);
	const dispatch = useAppThunkDispatch();
	useEffect(() => {
		if (currentCourse) {
			dispatch(unitsGetAll(currentCourse.id));
		}
	}, [currentCourse]);

	const classrooms = useTypedSelector(
		(state) => state.dropdowns.dropdownClassrooms
	);


	const auth = useTypedSelector((state) => state.auth);
	const hasEditPermission =
		auth.user.type === 'super' || auth.permissions.has('u-cr');

	const defaultValues = React.useMemo<CourseFormData>(() => {
		if (currentCourse) {
		
			const defaults: CourseFormData = {
				...currentCourse,
				correspondingCourseClassroomId:
					currentCourse.correspondingCourse?.classroom_id,
			};
			return defaults;
		} else {
			return {} as CourseFormData;
		}
	}, []);

	const addValues = React.useMemo<CourseFormData>(() => {
		const defaults: any = {
			unlimited: true,
			payment_methods_allowed: '*',
			active: false,
			buyable: false,
			free: false,
			scores_view: false,
			Set_buyable_period: false,
			Set_active_period: false,
			sequential_units: true,
		};
		return defaults;
	}, []);

	const {
		handleSubmit,
		setValue,
		getValues,
		control,
		watch,
		formState: { isValid, isDirty },
	} = useForm<CourseFormData>({
		defaultValues: mode === FormMode.UPDATE ? defaultValues : addValues,
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(CourseFormSchema),
	});

	const { displaySnackbar } = useSnackbar();

	const params = useParams<{ label: string; id: string }>();
	const watchUnlimited = watch('unlimited');
	const watchSequence = watch('add_to_sequence');

	const correspondingClassroom = watch('correspondingCourseClassroomId');
	
	useEffect(() => {
		if(!correspondingClassroom) return;
		dispatch(fetchCoursesDropdown(correspondingClassroom))
	},[correspondingClassroom])

	const courses = useTypedSelector((state) => state.dropdowns.dropdownCourses);

	useEffect(() => {
		const label = params.label;
		dispatch(getClassroomByLabel({ label }));
	}, [params.label]);

	useEffect(() => {
		if (currentClassroom?.id) {
			const id = String(currentClassroom?.id);
			dispatch(getClassroomSections({ id }));
		}
	}, [currentClassroom?.id, dispatch]);

	useEffect(() => {
		if (params.id || mode === FormMode.UPDATE) {
			const id = String(params.id);
			dispatch(getCourseById({ id }));
		}
	}, [params.id]);
	const old_price_input = document.querySelector('#old_price');
	const price_input = document.querySelector('#price');
	const numberRegx = /[0-9\/]+/;
	old_price_input?.addEventListener('keypress', (e: any) => {
		if (!numberRegx.test(e.key)) {
			e.preventDefault();
		}
	});
	price_input?.addEventListener('keypress', (e: any) => {
		if (!numberRegx.test(e.key)) {
			e.preventDefault();
		}
	});

	useEffect(() => {
		if (mode === FormMode.UPDATE && currentCourse) {
			currentCourse?.capacity &&
				setValue('capacity', currentCourse?.capacity as number);
			setValue('unlimited', currentCourse?.capacity === null);

			setValue('name', currentCourse?.name);
			setValue('code', currentCourse?.code);
			setValue('preview_url', currentCourse?.preview_url);
			currentCourse?.order && setValue('order', currentCourse?.order);
			currentCourse?.description &&
				setValue('description', currentCourse?.description);
			setValue('section_id', currentCourse?.section_id);
			setValue('price', currentCourse?.price);
			setValue('old_price', currentCourse?.old_price);
			currentCourse?.expiry_duration &&
				setValue('expiry_duration', currentCourse?.expiry_duration);
			setValue('free', currentCourse?.free);
			getValues('price') > 0 ? setValue('free', false) : setValue('free', true);
			setValue(
				'payment_methods_allowed',
				currentCourse?.payment_methods_allowed
			);
			setValue('active', currentCourse?.active);
			setValue('buyable', currentCourse?.buyable);
			setValue('scores_view', currentCourse?.scores_view);
			setValue('Set_buyable_period', currentCourse?.Set_buyable_period);

			let buyablePeriod: any =
				currentCourse?.buy_end_date &&
				toIsoString(new Date(currentCourse?.buy_end_date));
			if (currentCourse?.buy_end_date) setValue('buy_end_date', buyablePeriod);

			let activeStart_date: any =
				currentCourse?.active_start_date &&
				toIsoString(new Date(currentCourse?.active_start_date));
			if (currentCourse?.active_start_date)
				setValue('active_start_date', activeStart_date);

			let activeEnd_date: any =
				currentCourse?.active_end_date &&
				toIsoString(new Date(currentCourse?.active_end_date));
			if (currentCourse?.active_end_date)
				setValue('active_end_date', activeEnd_date);

			let buyStart_date: any =
				currentCourse?.buy_start_date &&
				toIsoString(new Date(currentCourse?.buy_start_date));
			if (currentCourse?.buy_start_date)
				setValue('buy_start_date', buyStart_date);

			let expiryDate: any =
				currentCourse?.expiry_date &&
				toIsoString(new Date(currentCourse?.expiry_date));
			if (currentCourse?.expiry_date) setValue('expiry_date', expiryDate);
		}
	}, [currentCourse, getValues, mode, setValue]);

	useEffect(() => {
		if (mode === FormMode.UPDATE && currentCourse) {
			setValue('section_id', currentCourse?.section_id);
		}
	}, [currentCourse, setValue, mode]);

	useEffect(() => {
		if (mode === FormMode.UPDATE) {
			currentCourse?.capacity &&
				setValue('capacity', currentCourse?.capacity as number);
			getValues('capacity') === null || undefined
				? setValue('unlimited', true)
				: setValue('unlimited', false);
		}
	}, [getValues, setValue, currentCourse?.capacity, mode]);

	useEffect(() => {
		if (mode === FormMode.ADD) {
			getValues('add_to_sequence');
			watchSequence === (true || undefined) && getValues('current_course');
		}
	}, [getValues, mode, currentCourse, watchSequence]);

	useEffect(() => {
		if (
			mode === FormMode.UPDATE &&
			currentCourse &&
			getValues('active_start_date')
		) {
			getValues('active_start_date') !== null ||
			getValues('active_end_date') !== null
				? setValue('Set_active_period', true)
				: setValue('Set_active_period', false);
		}
	}, [currentCourse, getValues, mode, setValue]);

	useEffect(() => {
		if (
			mode === FormMode.UPDATE &&
			currentCourse &&
			getValues('buy_start_date')
		) {
			getValues('buy_start_date') !== null || getValues('buy_end_date') !== null
				? setValue('Set_buyable_period', true)
				: setValue('Set_buyable_period', false);
		}
	}, [currentCourse, getValues, mode, setValue]);

	useEffect(() => {
		if (watch('free')) setValue('price', 0);
	}, [watch('free')]);

	useEffect(() => {
		if (watchUnlimited) {
			setValue('capacity', null, { shouldValidate: true });
		}
	}, [watchUnlimited, setValue]);

	useEffect(() => {
		if (watch('Set_buyable_period') === false) {
			setValue('buy_start_date', null, { shouldValidate: true });
			setValue('buy_end_date', null, { shouldValidate: true });
		}
	}, [watch('Set_buyable_period')]);
	useEffect(() => {
		if (watch('Set_active_period') === false) {
			setValue('active_start_date', null, { shouldValidate: true });
			setValue('active_end_date', null, { shouldValidate: true });
		}
	}, [watch('Set_active_period')]);
	const OnSubmitForm = async (formData: CourseFormData) => {
		if (!currentClassroom) return;
		try {
			if (mode === FormMode.UPDATE) {
				const { data } = await updateCourse(formData, currentCourse?.id);
			} else {
				const { data } = await createCourse(formData, currentClassroom?.id);
				history.push({
					pathname: `/dashboard/classrooms/classroom/${currentClassroom?.label}/course/${data.data.id}/edit/info`,
				});
			}
			displaySnackbar(
				'success',
				`${mode === 1 ? 'Edited ' : 'Created '}  Course Successfully`
			);
		} catch (error) {
			displaySnackbar(
				'error',
				`Couldn't ${mode === 1 ? 'edit ' : 'create'} course`
			);
		}
	};

	const onCancel = () => {
		history.goBack();
	};

	return (
		<form onSubmit={handleSubmit(OnSubmitForm)}>
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<TitleStyled> {mode === 1 ? 'Edit Course' : 'Add Course'}</TitleStyled>
			</SectionLayout>
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl
					control={control}
					required
					name="name"
					label={'Name'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					select
					name="section_id"
					label={'Section'}
					disabled={!hasEditPermission}
				>
					{currentClassroomSections?.map((section, index) => (
						<MenuItem value={section.id} key={`section-${section.id}-${index}`}>
							{section.name + ' - ' + section.classroomTab.name}
						</MenuItem>
					))}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					name="code"
					label={'Code'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					width="4.75rem"
					type="number"
					inputMode="numeric"
					name="order"
					label={'Order'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					name="preview_url"
					label={'Preview'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					multiline
					name="description"
					label={'Description'}
					disabled={!hasEditPermission}
				/>
			</SectionLayout>
			{mode === FormMode.ADD && (
				<>
					<Divider />
					<SectionLayout
						gridGap={'2rem'}
						gridTemplateColumns={{ sm: 'repeat(1,1fr)' }}
					>
						<EdFormControl
							control={control}
							switch
							name="add_to_sequence"
							label="In sequence"
							defaultChecked={true}
							disabled={!hasEditPermission}
						/>
						{watchSequence === false ? null : (
							<EdFormControl
								control={control}
								switch
								name="current_course"
								label="Current course"
								defaultChecked={true}
								disabled={!hasEditPermission}
							/>
						)}
					</SectionLayout>
				</>
			)}
			<Divider />
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<EdFormControl
						control={control}
						width="4.75rem"
						id="price"
						required
						name="price"
						label={'Price'}
						disabled={watch('free') || !hasEditPermission}
					/>
					<Controller
						name="free"
						control={control}
						render={({ field }) => (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									checked={watch('free') ? true : false}
									{...field}
									id="free"
									disabled={!hasEditPermission}
								/>
								<label>Free</label>
							</Box>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{watchUnlimited ? (
						<EdFormControl
							control={control}
							width="4.75rem"
							disabled={true}
							type="number"
							inputMode="numeric"
							name={`capacity`}
							label="Capacity"
							required
							style={{ backgroundColor: '#f3f3f3' }}
							value={''}
						/>
					) : (
						<EdFormControl
							control={control}
							width="4.75rem"
							disabled={!hasEditPermission}
							type="number"
							inputMode="numeric"
							name={`capacity`}
							label="Capacity"
							required
						/>
					)}
					<Controller
						name="unlimited"
						control={control}
						render={({ field }) => (
							<FlexLayout
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<Checkbox
									checked={watchUnlimited ? true : false}
									{...field}
									id="Unlimited"
									disabled={!hasEditPermission}
								/>
								<label>Unlimited</label>
							</FlexLayout>
						)}
					/>
				</Box>
				<EdFormControl
					control={control}
					width="4.75rem"
					inputMode="numeric"
					name="old_price"
					id="old_price"
					label={'Price before discount'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					select
					name="payment_methods_allowed"
					label={'Payment'}
					disabled={!hasEditPermission}
				>
					<MenuItem key="all" value="*">
						All
					</MenuItem>
					<MenuItem key="wallet" value="wallet">
						Wallet
					</MenuItem>
					<MenuItem key="scratchcard" value="scratchcard">
						Scratchcard
					</MenuItem>
				</EdFormControl>

				<EdFormControl
					control={control}
					switch
					name="skip_expiry_for_completed"
					label={'Skip Expiry For Completed'}
				/>
			</SectionLayout>
			<Divider />
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}
				>
					<EdFormControl
						control={control}
						switch
						checked={watch('active')}
						name="active"
						label={'Active'}
						disabled={watch('Set_active_period') || !hasEditPermission}
					/>
					<Controller
						name="Set_active_period"
						control={control}
						render={({ field }) => (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									checked={watch('Set_active_period') ? true : false}
									{...field}
									id="Set_active_period"
									disabled={!hasEditPermission}
								/>
								<label>Set Period</label>
							</Box>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{watch('Set_active_period') && (
						<>
							<EdFormControl
								width="16rem"
								type="datetime-local"
								control={control}
								name="active_start_date"
								label={'start'}
								sx={{ marginBottom: '0.5rem' }}
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								width="16rem"
								type="datetime-local"
								control={control}
								name="active_end_date"
								label={'end'}
								sx={{ marginTop: '0.5rem' }}
								disabled={!hasEditPermission}
							/>
						</>
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}
				>
					<EdFormControl
						control={control}
						switch
						name="buyable"
						checked={watch('buyable')}
						label={'Buyable'}
						disabled={watch('Set_buyable_period') || !hasEditPermission}
					/>

					<Controller
						name="Set_buyable_period"
						control={control}
						render={({ field }) => (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									checked={watch('Set_buyable_period') ? true : false}
									{...field}
									id="Set_buyable_period"
									disabled={!hasEditPermission}
								/>
								<label>Set Period</label>
							</Box>
						)}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					{watch('Set_buyable_period') && (
						<>
							<EdFormControl
								width="16rem"
								type="datetime-local"
								control={control}
								name="buy_start_date"
								label={'start'}
								sx={{ marginBottom: '0.5rem' }}
								disabled={!hasEditPermission}
							/>
							<EdFormControl
								width="16rem"
								type="datetime-local"
								control={control}
								name="buy_end_date"
								label={'end'}
								sx={{ marginTop: '0.5rem' }}
								disabled={!hasEditPermission}
							/>
						</>
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<EdFormControl
						control={control}
						width="4.75rem"
						type="number"
						inputMode="numeric"
						name="expiry_period"
						label={'Expiry duration'}
						disabled={!hasEditPermission}
					/>
					<SpanStyle>Days</SpanStyle>
				</Box>
				<EdFormControl
					width="16rem"
					type="datetime-local"
					control={control}
					name="expiry_date"
					id="expiry_date"
					label={'Expiry date'}
					disabled={!hasEditPermission}
				/>
				<EdFormControl
					control={control}
					checked={watch('scores_view')}
					switch
					name="scores_view"
					label={'Scores view'}
					disabled={!hasEditPermission}
				/>
			</SectionLayout>
			<Divider label='Course Reference Details' />
			<SectionLayout>
				<EdFormControl
					control={control}
					name="correspondingCourseClassroomId"
					label={'Classroom'}
					select
				>
					{classrooms.map(({ id, title }) => (
						<MenuItem value={id} key={id}>
							{title}
						</MenuItem>
					))}
				</EdFormControl>
				<EdFormControl
					control={control}
					name="corresponding_course_id"
					label={'Corresponding Course'}
					select
				>
					{courses.map(({ id, name }) => {

						return (
							<MenuItem value={id} key={id}>
								{name}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					label={'Quiz'}
					disabled={!hasEditPermission}
					control={control}
					name="quiz_id"
					select
				>
					{fetchAll.map((_) => {
						if (_.unitType.name !== 'test') return null;
						return (
							<MenuItem value={_.id} key={_.id}>
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					label={'Homework'}
					disabled={!hasEditPermission}
					control={control}
					name="assignment_id"
					select
				>
					{fetchAll.map((_) => {
						if (_.unitType.name !== 'test') return null;
						return (
							<MenuItem value={_.id} key={_.id}>
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>
			</SectionLayout>
			{hasEditPermission && (
				<ActiosnContainer>
					<Button variant="contained" color="warning" onClick={onCancel}>
						Cancel
					</Button>
					<Spacer mx={'.5rem'} />
					<Button
						type="submit"
						disabled={!isDirty || !isValid}
						variant="contained"
					>
						Save
					</Button>
				</ActiosnContainer>
			)}
			<DevTool control={control} />
		</form>
	);
};

export default BasicInfo;
