import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	EdFormControlLabel,
	EdFormControlError,
} from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { Editor as tinymceEditor } from 'tinymce';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTypedSelector } from '@Features/store';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { Typography } from '@eduact/ed-system';
import {
	Button,
	Checkbox,
	InputAdornment,
	ListItemText,
	MenuItem,
} from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import AddTag from '../../../Components/AddTag';
import EdButtonMenu from '@Components/UI/Buttons/EdButtonMenu/EdButtonMenu';
import { DevTool } from '@hookform/devtools';
import { TypedTestQuestionPayload } from '../../../Types/TestQuestion';
import { ESSAY_SCHEMA } from '../../../Schema/Essay.schema';
import { useSnackbar } from '@Providers/useSnackbar';
import { useGotoParentRoute } from '@Providers/Routes/RoutesProvider';
import { TestQuestionsRequester } from '../../../Services/TestQuestions/TestQuestionsRequester';
import AnswerSchemaList from '../../../Components/AnswerSchemaList';

const AddEssayQuestion = () => {
	const [addedId, setAddedId] = useState<number | undefined>();
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { dropdownTags } = useTypedSelector((state) => state.dropdowns);
	const editorRef = useRef<tinymceEditor | null>(null);
	const feedbackEditorRef = useRef<tinymceEditor | null>(null);

	const { control, watch, formState, handleSubmit, setValue, reset } = useForm<
		TypedTestQuestionPayload<'essay'>
	>({
		mode: 'all',
		resolver: yupResolver(ESSAY_SCHEMA),
		defaultValues: {
			test_id: currentEntity?.id ?? 0,
			tags: [],
			weight: NaN,
			answer_schema: 'text',
		},
	});
	const { isDirty, isValid, isSubmitting } = formState;
	const watchTags = watch('tags');

	useEffect(() => {
		if (addedId !== undefined) {
			setValue('tags', [...watchTags, addedId]);
		}
	}, [addedId]);

	const { displaySnackbar } = useSnackbar();
	const goToParent = useGotoParentRoute();

	const onSave = async (form: TypedTestQuestionPayload<'essay'>) => {
		try {
			await TestQuestionsRequester.instance.create(form);
			displaySnackbar('success', 'Created question successfully');
			goToParent();
		} catch (error) {
			displaySnackbar('error', "Couldn't create question");
		}
	};

	const onSaveWithReset = async (form: TypedTestQuestionPayload<'essay'>) => {
		try {
			await TestQuestionsRequester.instance.create(form);
			displaySnackbar('success', 'Created question successfully');
			reset({
				content: '',
				feedback: '',
				weight: NaN,
				tags: [],
				test_id: currentEntity?.id ?? 0,
			});
		} catch (error) {
			displaySnackbar('error', "Couldn't create question");
		}
	};

	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<FlexLayout flex="1">
				<Controller
					control={control}
					name="content"
					render={({
						field: { value, onChange, ...props },
						fieldState: { error },
					}) => {
						return (
							<FlexLayout flexDirection={'column'} width="100%">
								<TinyMceControl
									{...props}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (editorRef.current = editor)}
								/>
								<EdFormControlError error={error}>
									{error?.message}
								</EdFormControlError>
							</FlexLayout>
						);
					}}
				/>
			</FlexLayout>
			<Spacer my="1.5rem" />
			<GridLayout gridTemplateColumns={'repeat(1,1fr)'} gridGap="1.875rem">
				<FlexLayout alignItems="center" mb="2rem">
					<AnswerSchemaList control={control} />
					<Spacer mx="0.5rem" />
				</FlexLayout>
				<FlexLayout alignItems="center">
					<EdFormControl
						width="5rem"
						type="number"
						control={control}
						name="weight"
						label="Question Weight"
					/>
					<Spacer mx="0.5rem" />
					<Typography fontWeight="600">Points</Typography>
				</FlexLayout>
				<FlexLayout gridGap="1rem">
					<EdFormControl
						label="Tags"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EdIcon mIconType="Regular">local_offer</EdIcon>
								</InputAdornment>
							),
						}}
						SelectProps={{
							multiple: true,
							renderValue: (value) => {
								const values = Array.from(value as number[]);
								return (
									<>
										{values.map((v) => {
											const tag = dropdownTags.find((_) => _.id === v);
											return <>{tag?.name},</>;
										})}
									</>
								);
							},
						}}
						control={control}
						name="tags"
						select
						multiple
						renderValues={(value) => {
							return (
								<>
									<Spacer mb="1rem" />
									<FlexLayout
										overflow="auto"
										py={'0.4rem'}
										flexWrap="wrap"
										gridGap="0.5rem"
										width="18.75rem"
									>
										{dropdownTags
											.filter((_) => watchTags.includes(_.id))
											.map((tag) => {
												return (
													<TagChip
														bgColor={tag.theme}
														icon={<EdIcon>local_offer</EdIcon>}
														label={tag.name}
													/>
												);
											})}
									</FlexLayout>
								</>
							);
						}}
					>
						{dropdownTags.map((tag, index) => {
							return (
								<MenuItem value={tag.id} key={`tag-${tag.id}`}>
									<Checkbox
										sx={{ color: tag.theme }}
										checked={watchTags.includes(tag.id)}
									/>
									<ListItemText primary={tag.name} />
								</MenuItem>
							);
						})}
					</EdFormControl>
					<AddTag setAddedId={setAddedId} />
				</FlexLayout>
				<FlexLayout>
					<EdFormControlLabel>Feedback</EdFormControlLabel>
					<Controller
						control={control}
						name="feedback"
						render={({ field: { onChange, value, ...props }, fieldState }) => {
							return (
								<TinyMceControl
									{...props}
									min_height={200}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (feedbackEditorRef.current = editor)}
								/>
							);
						}}
					/>
				</FlexLayout>
			</GridLayout>
			{!currentEntity.active && (
				<FlexLayout mt="1.688rem" justifyContent="flex-end">
					<Button
						disabled={!formState.isDirty}
						variant="contained"
						color="warning"
						onClick={() => goToParent()}
					>
						Cancel
					</Button>
					<Spacer mx="0.5rem" />
					<EdButtonMenu
						onClick={handleSubmit(onSave)}
						disabled={isSubmitting || !isValid || !isDirty}
						variant="contained"
						MenuItems={
							<div>
								<MenuItem onClick={handleSubmit(onSaveWithReset)}>
									Save and add another
								</MenuItem>
							</div>
						}
					>
						Save
					</EdButtonMenu>
				</FlexLayout>
			)}
			<DevTool control={control} />
		</FlexLayout>
	);
};

export default AddEssayQuestion;
