import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import StudentCardId from '@Pages/Students/Components/StudentCardID';
import { Box, FlexLayout, GridLayout } from '@eduact/ed-system';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import styled from 'styled-components';
import { useSnackbar } from '@Providers/useSnackbar';
import { usePageLoader } from '@Providers/LoadingProvider';
import grid from '@Assets/img/grid.jpg';
import { SingleStudentData } from '@Services/students/students.res.types';
import { Group } from '@Pages/ClassRooms/Types/Groups.types';
import { ClassroomEnrollments } from '@Services/classrooms/classrooms.res.types';

type Props = {
	fetchAll: ClassroomEnrollments[];
};

export const PrintCardsPreview = ({ fetchAll }: Props) => {
	const dispatch = useAppThunkDispatch();
	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	const perPage = 6;

	const numberOfPages = Math.round(fetchAll.length / perPage);

	const documentsRef = useRef<HTMLDivElement[]>([]);

	if (fetchAll.length === 0)
		return (
			<FlexLayout
				height={'100%'}
				flex={1}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<p>Please specify filter criteria on Print cards Table </p>
			</FlexLayout>
		);

	// const { setIsLoading, isLoading } = usePageLoader();

	const handlePrint = () => {
		// setIsLoading(true);
		handleDownloadPDF().then(() => {
			// setIsLoading(false);
		});
	};
	const handleDownloadPDF = async () => {
		if (!documentsRef.current) return;

		const pdf = new jsPDF('p', 'cm', 'a4');
		const a4_ratio = 1.41;
		const [imgWidth, pageHeight] = [21.0, 29.7];
		for (let refId = 0; refId < documentsRef.current.length; refId++) {
			const ref = documentsRef.current[refId];
			const canvas = await html2canvas(ref);
			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			const imgData = canvas.toDataURL('image/png');
			pdf.addImage(imgData, 'PNG', 0, 3.7, imgWidth, 0, undefined, 'FAST');
			if (refId !== documentsRef.current.length - 1) pdf.addPage();
		}
		pdf.autoPrint();

		pdf.setProperties({
			title: 'print-cards',
			author: 'eduact',
			creator: 'eduact',
			keywords: 'print,cards',
			subject: 'Students Cards to be printed',
		});
		const blob = pdf.output('blob');
		const file = new File([blob], 'print-cards', { type: blob.type });
		//  );
		//  );
		window.open(URL.createObjectURL(file as Blob), '_blank');
		// window.open(pdf.output('dataurlstring'), '_blank');
		// pdf.output('dataurlnewwindow');
	};

	return (
		<Box>
			<DownloadBanner padding={'2rem'} justifyContent={'flex-end'}>
				<Button variant="contained" onClick={handlePrint}>
					PRINT
				</Button>
			</DownloadBanner>
			<Box py={'4rem'}>
				{Array(numberOfPages)
					.fill(0)
					.map((item, index) => {
						return (
							<GridLayout
								key={`cards-page-${index}`}
								ref={(elem) => {
									if (elem) documentsRef.current[index] = elem;
								}}
								py="1rem"
								gridTemplateColumns={'repeat(2,1fr)'}
								justifyItems={'center'}
								gridRowGap={'4rem'}
							>
								{fetchAll
									.slice(index * perPage, (index + 1) * perPage)
									.map((student) => (
										<StudentCardId key={student.user?.uuid} student={student} />
									))}
							</GridLayout>
						);
					})}
				{/* <GridLayout
					ref={documentsRef}
					gridTemplateColumns={'repeat(2,1fr)'}
					justifyItems={'center'}
					gridRowGap={'4rem'}
				>
					{fetchAll.map((student) => (
						<StudentCardId key={student.uuid} student={student} />
					))}
				</GridLayout> */}
			</Box>
		</Box>
	);
};

const DownloadBanner = styled(FlexLayout)`
	background: linear-gradient(0deg, transparent, #fff, #fff);
	z-index: 3;
	position: sticky;
	top: 0;
`;
