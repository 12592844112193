import { Icon, Paper, Tooltip } from '@mui/material';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import RouteSegmentList from './Components/RouteSegmentList';
import {
	StyledDrawer,
	MenuHeader,
	StyledMenuCloseIcon,
	LogoImage,
	StyledMenuItem,
	StyledMenuList,
} from './Menu.styled';
import { routes } from '../../../constants/routes';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { useDispatch } from 'react-redux';
import { toggleAppDrawer } from '@Features/settings/settingsSlice';

export type SideMenuProps = {
	$sidemenuopened: boolean;
};
type SideMenuContextType = {
	opened: boolean;
	setOpen?: React.Dispatch<boolean>;
};
export const SideMenuContext = createContext<SideMenuContextType>({
	opened: true,
});

const Menu: React.FC = () => {
	const [opened, setOpened] = useState(true);
	const [showTooltip, setShowTooltip] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		window.addEventListener('keypress', handleWindowKeydown);

		return () => {
			window.removeEventListener('keypress', handleWindowKeydown);
		};
	}, []);

	const handleWindowKeydown = (e: KeyboardEvent) => {
		const { key } = e;
		if (key === '[') {
			handleToggleDrawer();
		}
	};

	const handleToggleDrawer = () => {
		setOpened((prev) => !prev);
	};

	useEffect(() => {
	
		dispatch(toggleAppDrawer(opened));
	}, [opened]);

	return (
		<SideMenuContext.Provider value={{ opened, setOpen: setOpened }}>
			<StyledDrawer variant="persistent" open={opened}>
				<MenuHeader>
					<LogoImage open={opened} />
					<Tooltip
						open={showTooltip}
						title={
							<FlexLayout alignItems="center">
								<div>{opened ? 'Collapse' : 'Expand'}</div>
								<Spacer mx="0.3rem" />
								<Paper variant="outlined" sx={{ padding: '0.2rem' }}>
									[
								</Paper>
							</FlexLayout>
						}
						placement="right"
					>
						<div>
							<StyledMenuCloseIcon
								open={opened}
								onClick={handleToggleDrawer}
								color="primary"
								fontSize="large"
							/>
						</div>
					</Tooltip>
				</MenuHeader>
				<StyledMenuList>
					<StyledMenuItem $sidemenuopened={opened}>
						<Tooltip placement="right" title="Home">
							<Icon>home</Icon>
						</Tooltip>
						<label>Home</label>
					</StyledMenuItem>
					{routes.map((_, index) => {
						return (
							<RouteSegmentList key={`${index}-${_.title}-rsl`} item={_} />
						);
					})}
				</StyledMenuList>
			</StyledDrawer>
			<MenuLineContainer
				onMouseOver={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<MenuLine />
			</MenuLineContainer>
		</SideMenuContext.Provider>
	);
};

export default Menu;

const MenuLine = styled.div`
	width: 3px;
	background: ${(props) => props.theme.palette.primary.main};
	height: 100%;
	position: relative;
	transition: all ease-in-out 150ms;
	opacity: 0;
	button {
		position: absolute;
		top: 15%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 999999;
	}
`;
const MenuLineContainer = styled.div`
	width: 24px;
	height: 100vh;
	max-height: 100vh;
	position: sticky;
	top: 0;
	z-index: 100000;

	&:hover {
		${MenuLine} {
			opacity: 1;
		}
	}
`;
