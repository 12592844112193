import { IAllProps } from '@tinymce/tinymce-react';
import React from 'react';

import { QuillEditor } from './QuillEditor';
import { FlexLayout } from '@eduact/ed-system';

type Props = { min_height?: number } & IAllProps;
const TinyMceControl: React.FC<Props> = (props) => {
	return (
		<>
			<FlexLayout flexDirection={'column'}>
				<QuillEditor
					disabled={props.disabled}
					value={props.value}
					onEditorChange={props.onEditorChange}
					min_height={props.min_height || 200}
				/>
			</FlexLayout>
			{/* <Editor
				apiKey="t0836u9ihhfgcthx7ze3a146uk6pjje44mq82ij090qjv0ue"
				{...BaseTinyMceConfig}
				init={{
					...BaseTinyMceConfig.init,
					min_height: props.min_height ?? 400,
					images_upload_handler: async (blobInfo) => {
						 );
						const {
							data: {
								data: { uri },
							},
						} = await uploadPicture({
							folder: 'tinymc',
							photo: blobInfo.blob(),
						});
						return uri;
					},
				}}
				{...props}
			/> */}
		</>
	);
};

export default TinyMceControl;
