import { object, string } from 'yup';
import { TestQuestionBaseSchema } from './TestQuestion';

export const GAP_SCHEMA = TestQuestionBaseSchema.concat(
	object({
		type: string().default('gap'),
		content: string().test({
			test: (value) => {
				const gaps = value?.match(/(\[(?:.*?)\])/g);
				if (!gaps) return false;
				for (let index = 0; index < gaps.length; index++) {
					const gap = gaps[index];
					const isCorrect = gap.match(/.*\((.+)\).*/gm);
					if (!isCorrect) return false;
				}
				return true;
			},
			message: 'Question is not correct',
		}),
	})
);
