import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import EdEmptyValue from '@Components/UI/Utilities/EdEmptyValue/EdEmptyValue';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import AddClassroomButton from '@Pages/ClassRooms/Components/AddClassroomButton';
import {
	groupsDeleteById,
	groupsGetAll,
} from '@Pages/ClassRooms/Slices/Groups/Groups.slice';
import { TableCell } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'reactstrap';
import AddGroupButton from '../../components/AddGroupButton';
import { useHistory } from 'react-router-dom';
import { Typography } from '@eduact/ed-system';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { useSnackbar } from '@Providers/useSnackbar';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'Name', fieldName: 'name', canSort: true },
	{ displayName: 'Code', fieldName: 'code', canSort: true },
	{ displayName: 'Description', fieldName: 'description' },
];
export const GroupsTable = () => {
	const dispatch = useAppThunkDispatch();
	const [toBeDeleted, SetToBeDelete] = useState<number | undefined>();
	const [openModel, setOpenModel] = useState<boolean>(false);
	const [needRefresh, setNeedRefresh] = useState(true);
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const onPageRequestChange = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportData,
		}: onPageChangeRequestMeta) => {
		
			if (currentClassroom) {
				dispatch(
					groupsGetAll({
						page: activePage,
						filters: filters,
						perPage: perPage ?? 10,
						query,
						export: exportData ?? false,
						sortBy: sortBy,
						from: startDate,
						to: endDate,
						classroom_id: currentClassroom?.id,
					})
				);
				setNeedRefresh(false);
			}
		},
		[dispatch, currentClassroom?.id, needRefresh]
	);

	const { fetchAll, filters, meta, selectedFilters, dateRange, query, sortBy } =
		useTypedSelector((state) => state.Groups);

	const history = useHistory();

	const handleModelOpen = (id: number) => {
		setOpenModel(true);
		SetToBeDelete(id);
	};

	const { displaySnackbar } = useSnackbar();
	const handleModelClose = async (close: boolean, confirm?: boolean) => {
		if (confirm && toBeDeleted) {
			try {
				const resp = await dispatch(
					groupsDeleteById({ id: toBeDeleted, payload: { id: toBeDeleted } })
				).unwrap();
				setNeedRefresh(true);
				SetToBeDelete(undefined);
				displaySnackbar('success', resp.message);
			} catch (error) {
				displaySnackbar('error', error.message);
			}
		}
		setOpenModel(false);
	};
	return (
		<>
			<div className="classroom_head__container">
				<AddGroupButton />
			</div>
			<EdConfirmModal
				text="Are you sure you want to delete this Group ?"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<MainTable
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestChange}
				RowsperPage={meta?.per_page}
				$hasActions
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((code) => {
							return (
								<MainTableRow onClick={() => history.push(`groups/edit/${code.id}`)}>
									<TableCell>{code.name}</TableCell>
									<TableCell>{code.code}</TableCell>
									<TableCell>
										{' '}
										<Typography
											maxWidth={'20ch'}
											overflow={'hidden'}
											textOverflow={'ellipsis'}
										>
											{code.description}
										</Typography>
									</TableCell>
									<TableCell>
										<EdSpeedDial ariaLabel="Actions">
											<EdSpeedDialAction
												sx={{
													'&:hover': {
														backgroundColor: 'rgba(90,192,252, 0.25)',
													},
												}}
												key="delete"
												icon={<EdIcon color="error">delete</EdIcon>}
												tooltipTitle="Delete"
												onClick={(e) => {
													e.stopPropagation();
													handleModelOpen(code.id);
												}}
											/>
											{/* <EdSpeedDialAction
												onClick={() => history.push(`groups/edit/${code.id}`)}
												key="edit"
												tooltipTitle="Edit"
												icon={<EdIcon>edite</EdIcon>}
												aria-label=""
											/> */}
										</EdSpeedDial>
									</TableCell>
								</MainTableRow>
							);
						});
					}
				}}
			/>
		</>
	);
};
