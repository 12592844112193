import React, { useEffect, useState } from 'react';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import {
	testModelsDeleteById,
	testModelsGetAll,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/TestModel/TestModel.slice';
import { TestModel } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestModel.types';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, Spacer } from '@Styled/utilities';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { Button, ButtonGroup, Divider, Paper } from '@mui/material';
import styled from 'styled-components';
import { blue } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';

const TestModelsTable = () => {
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { fetchAll } = useTypedSelector((state) => state.TestModels);
	const dispatch = useAppThunkDispatch();
	const history = useHistory();

	useEffect(() => {
		if (currentEntity && currentEntity?.id)
			dispatch(testModelsGetAll(currentEntity.id));
	}, [currentEntity, dispatch]);

	const [modelOpen, setModelOpen] = useState(false);
	const [modelToDelete, setModelToDelete] = useState<number | undefined>();
	const onConfirmModelDelete = (model: TestModel) => {
		setModelOpen(true);
		setModelToDelete(model.id);
	};
	const { displaySnackbar } = useSnackbar();

	const handleDeleteClose = async (close: boolean, confirm?: boolean) => {
		if (confirm) {
			try {
				if (modelToDelete) {
					await dispatch(testModelsDeleteById(modelToDelete)).unwrap();
					setModelToDelete(undefined);
				}
			} catch (e) {
				displaySnackbar('error', e.error);
			}
		}
		setModelOpen(close);
	};
	return (
		<div>
			{fetchAll.length === 0 && (
				<>
					<NoteContainer elevation={0}>
						<FlexLayout>
							<EdIcon mIconType="Outlined">info</EdIcon>
							<Spacer mx={'0.5rem'} />
							<NoteContent>
								<strong>Warning:</strong>
								No test models created, by creating a test model, original test
								is discarded and one of the created models will be drawn
								randomly for the student to solve. All test models must have
								save overall score.
							</NoteContent>
						</FlexLayout>
					</NoteContainer>
					<Spacer mb={'1rem'} />
				</>
			)}
			<FlexLayout justifyContent={'flex-end'}>
				<EdButton
					disabled={currentEntity.active}
					onClick={() => history.push(`${history.location.pathname}/add`)}
					variant="contained"
					edcolor={'dodgerBlue'}
				>
					Create New Model
				</EdButton>
			</FlexLayout>
			<EdConfirmModal
				open={modelOpen}
				text={'Are you sure to delete this test model ?'}
				handleClose={handleDeleteClose}
			/>
			{fetchAll.length > 0 && (
				<>
					<div>
						<TestModelTitle>Test Models</TestModelTitle>
						<Spacer mb={'1.25rem'} />
						<Divider />
					</div>
					{fetchAll.map((tsm, index) => {
						return (
							<TestModelGroup key={`${tsm.name}-${index}`}>
								<TestModelGroupInfo flex={'1'}>
									<div>{tsm.name}</div>
									<div>
										Questions: <strong>{tsm.questions_count}</strong>
									</div>
									<div>
										Overall Score: <strong>{tsm.questions_sum}</strong>
									</div>
								</TestModelGroupInfo>
								<ButtonGroup
									disabled={currentEntity.active}
									color={'warning'}
									variant={'text'}
								>
									<Button disabled>Preview</Button>
									<Button
										disabled={currentEntity.active}
										onClick={() =>
											history.push(`${history.location.pathname}/${tsm.id}`)
										}
									>
										Edit
									</Button>
									<Button
										disabled={currentEntity.active}
										onClick={() => onConfirmModelDelete(tsm)}
									>
										Delete
									</Button>
								</ButtonGroup>
							</TestModelGroup>
						);
					})}
				</>
			)}
		</div>
	);
};

export default TestModelsTable;

const NoteContainer = styled(Paper)`
	padding: 1rem;
	background: ${blue['50']};
`;
const NoteContent = styled.div`
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.375rem;
`;

const TestModelTitle = styled.span`
	font-weight: bold;
	margin-bottom: 1.25rem;
`;
const TestModelGroup = styled.div`
	padding: 1rem;
	display: flex;
	align-items: center;
`;
const TestModelGroupInfo = styled(FlexLayout)`
	div {
		flex: 1;
	}
`;
