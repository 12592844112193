import { useTypedSelector } from '@Features/store';
import { Button } from '@mui/material';
import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	DragDropContext,
	Droppable,
	DropResult,
	ResponderProvided,
} from 'react-beautiful-dnd';
import { DataProvider } from './Components/DataProvider';
import DraggableSequence from './Components/DraggableSequence';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import SyncUsersModel from './Components/SyncUsersModel';
import AddSequenceModel from './Components/AddSequenceModel';
import { SequenceManagementRequester } from './Services/Sequence.requester';
import { useSnackbar } from '@Providers/useSnackbar';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllCourses } from '@Features/classrooms/classroomSlice';

const SequenceManagement = () => {
	const { label } = useParams<{ label: string }>();
	const dispatch = useDispatch();
	const { displaySnackbar } = useSnackbar();
	const [openSyncModel, setOpenSyncModel] = useState<boolean>(false);
	const [openAddSequenceModel, setOpenAddSequenceModel] =
		useState<boolean>(false);
	const [inSequence, setInSequence] = useState<CourseWithPrerequisites[]>();
	const [outSequence, setOutSequence] = useState<CourseWithPrerequisites[]>();

	const { currentSequenceCours, currentClassroom, courses, perPage, page } =
		useTypedSelector((state) => state.classroom);


	const auth = useTypedSelector((state) => state.auth);
	const setSequencePermissions = auth.permissions.has('set-seq-cl');
	const [current, setCurrent] = useState<number | undefined>(
		currentSequenceCours
	);

	const onPageRequestUpdate = useCallback(() => {
		dispatch(
			getAllCourses({
				page: 1,
				perPage: 1000000,
				filters: [],
				query: '',
				sortBy: null,
				classroom: label as string,
			})
		);
	}, [label]);

	useEffect(() => {
		if (currentClassroom?.id) {
			onPageRequestUpdate();
		}
	}, [currentClassroom?.id]);
	useEffect(() => {
		setCurrent(currentSequenceCours);
	}, [currentSequenceCours]);

	const inSeq = useMemo(() => {
		if (courses === undefined) return;
		return courses
			?.filter((_) => _.sequence !== null)
			.sort((a: any, b: any) => (a?.sequence > b?.sequence ? 1 : -1));
	}, [courses]);

	const outSeq = useMemo(() => {
		if (courses === undefined) return;
		return courses?.filter((_) => _.sequence === null);
	}, [courses]);

	useEffect(() => {
		if (!inSeq || !outSeq) return;
		setInSequence(inSeq);
		setOutSequence(outSeq);
	}, [inSeq, outSeq]);

	const handleOnDragEnd = (result: DropResult, provided: ResponderProvided) => {
		if (result.destination) {
			inSeq?.splice(
				result.destination.index,
				0,
				inSeq?.splice(result.source.index, 1)[0]
			);
		}
	};

	const AddSetSequence = async () => {
		const sequenecesArray = inSequence?.map((sq, index) => ({
			course_id: sq.id,
			sequence: index + 1,
			current: current === sq.id,
		}));

		try {
			const {
				data: { message },
			} = await SequenceManagementRequester.getInstance().setSequence({
				classroom_id: currentClassroom?.id,
				sequence: sequenecesArray,
			});
			displaySnackbar('success', message ?? 'sequences set successfully');
		} catch (e) {
			displaySnackbar(
				'error',
				e.response.data.message ?? "couldn't set sequences"
			);
		}
	};

	return (
		<DataProvider
			inSequence={inSequence}
			outSequence={outSequence}
			setInSequence={setInSequence}
			setOutSequence={setOutSequence}
			current={current}
			setCurrent={setCurrent}
		>
			<CardContainer>
				<SyncUsersModel
					handleModelClose={setOpenSyncModel}
					openModel={openSyncModel}
				/>
				<AddSequenceModel
					handleModelClose={setOpenAddSequenceModel}
					openModel={openAddSequenceModel}
				/>
				<FlexLayoutStyle
					justifyContent="flex-end"
					marginTop="2.125rem"
					marginBottom="2.875rem"
					gridGap="1rem"
				>
					{(auth.user.type === 'super' ||
						auth.permissions.has('sync-seq-cl')) && (
						<SyncButton
							color="info"
							variant="contained"
							onClick={() => setOpenSyncModel(true)}
						>
							Sync students
						</SyncButton>
					)}
					{(auth.user.type === 'super' || setSequencePermissions) && (
						<AddButton
							color="primary"
							variant="contained"
							startIcon={<AddIcon />}
							onClick={() => setOpenAddSequenceModel(true)}
						>
							Add
						</AddButton>
					)}
				</FlexLayoutStyle>
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="SEQUENCES">
						{({ droppableProps, innerRef, placeholder }) => {
							return (
								<DraggableSequence
									disabled={
										!(auth.user.type === 'super' || setSequencePermissions)
									}
									droppableProps={droppableProps}
									innerRef={innerRef}
									placeholder={placeholder}
								/>
							);
						}}
					</Droppable>
				</DragDropContext>
				{(auth.user.type === 'super' || setSequencePermissions) && (
					<FlexLayout justifyContent="flex-end" marginTop="3rem">
						<ApplyButton
							disabled={
								currentSequenceCours === undefined
									? true
									: false && inSeq?.length === 0
							}
							color="primary"
							variant="contained"
							onClick={AddSetSequence}
						>
							Apply
						</ApplyButton>
					</FlexLayout>
				)}
			</CardContainer>
		</DataProvider>
	);
};

const FlexLayoutStyle = styled(FlexLayout)`
	button {
		font-size: 0.875rem;
		font-weight: 500;
	}
`;

const SyncButton = styled(Button)`
	background: #6c63ff;

	&:hover {
		background: #6c63ff;
	}
`;
const AddButton = styled(Button)`
	background: #2196f3;

	&:hover {
		background: #2196f3;
	}
`;

const ApplyButton = styled(Button)`
	width: 5.5rem;
	height: 2.625rem;
	font-weight: 500;
	font-size: 0.938rem;
`;

export default SequenceManagement;
