import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EditGapQuestion from './EditGapQuestion/EditGapQuestion';
import AddGapQuestion from './AddGapQuestion/AddGapQuestion';

// const AddGapQuestion = React.lazy(
// 	() => import('./AddGapQuestion/AddGapQuestion')
// );
const GapQuestion = () => {
	const { path } = useRouteMatch();
	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<React.Suspense fallback={<div>loading...</div>}>
				<Switch>
					<Route path={path} exact>
						<AddGapQuestion />
					</Route>
					<Route path={`${path}/:id`} exact>
						<>
							<EditGapQuestion />
						</>
					</Route>
				</Switch>
			</React.Suspense>
		</FlexLayout>
	);
};

export default GapQuestion;
